@media print {
  header, footer, .menu-filter, .openings {
      display: none !important;
  }

  .menu-card-item:nth-child(odd) {
    grid-column: 1;
    page-break-inside: avoid !important;
  }

  .menu-card-item:nth-child(even) {
    grid-column: 2;
    page-break-inside: avoid !important;
  }

  .category-items {
    margin-top: 5px !important;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 15px !important;
  }

  .h2-headline {
    padding-top: 10px;
  }

  .menu-app-div {
    > div {
      page-break-before: always;
      &:first-child {
        page-break-before: avoid;
      }
    }
  }

}