$pathPrefix: "../fonts/encode-sans-v8-latin/";

/* encode-sans-regular - latin */
@font-face {
    font-family: 'Encode Sans';
    font-style: normal;
    font-weight: 400;
    src: url('#{$pathPrefix}encode-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-500 - latin */
@font-face {
    font-family: 'Encode Sans';
    font-style: normal;
    font-weight: 500;
    src: url('#{$pathPrefix}encode-sans-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-v8-latin-500.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-600 - latin */
@font-face {
    font-family: 'Encode Sans';
    font-style: normal;
    font-weight: 600;
    src: url('#{$pathPrefix}encode-sans-v8-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-v8-latin-600.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-700 - latin */
@font-face {
    font-family: 'Encode Sans';
    font-style: normal;
    font-weight: 700;
    src: url('#{$pathPrefix}encode-sans-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-v8-latin-700.woff') format('woff'), /* Modern Browsers */
}
