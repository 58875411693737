a {
    @apply transition-colors cursor-pointer;
}

.stretched-link::after {
    content: "";
    @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}

.btn {
    @apply font-bold uppercase text-sm lg:text-base tracking-wider xl:tracking-widest px-6 py-2 lg:px-8 lg:py-3 inline-flex transition-colors justify-center text-center;
}

.btn-lg {
    @apply text-base lg:text-xl py-3 px-8 lg:py-6 lg:px-12 tracking-wider lg:tracking-widest;
}

.btn-primary {
    @apply bg-primary text-white hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-600;
}

.btn-black {
    @apply bg-black text-white hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-700;

    .hero & {
        @apply bg-gray-700 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-600;
    }
}

.btn-white {
    @apply bg-white text-black hover:bg-primary focus:bg-primary active:bg-primary;
}

.btn-outline-white {
    @apply bg-transparent border-2 border-white text-white hover:bg-white hover:text-black focus:text-black focus:bg-white active:text-black active:bg-white;

    &.btn-lg {
        @apply rounded;
    }
}