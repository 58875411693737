@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'fonts/encode-sans';
@import 'fonts/encode-sans-condensed';

@import 'components/buttons';
@import 'components/nav';
@import 'components/form';
@import 'components/menu';
@import 'components/modal';
@import 'components/block';

@import 'print';

@import 'vendor/swiper';
@import 'vendor/aos';

html {
  --brand: #9ec326;
  scrollbar-color: #9ec326 Canvas;
}

[x-cloak] {
  display: none;
}

body {
  @apply bg-repeat font-condensed;
  scroll-behavior: smooth;
  background-image: url('../images/bg-checkered.jpg');

  &.nav-open {
    @apply max-h-screen overflow-hidden;
  }
}

main#site {
  @apply mt-16;
}

.container-xl {
  @apply md:px-8 lg:px-16 xl:px-24 2xl:px-32
}

h1, h2, h3, h4 {
  strong {
    @apply text-primary font-semibold;
  }
}

svg {
  max-width: 100%;
  height: auto;
}

.mt-lg {
  @apply mt-12 md:mt-16 lg:mt-20 xl:mt-24 3xl:mt-32;
}

.mb-lg {
  @apply mb-12 md:mb-16 lg:mb-20 xl:mb-24 3xl:mb-32;
}

.-mb-lg {
  @apply -mb-12 md:-mb-16 lg:-mb-20 xl:-mb-24 3xl:-mb-32;
}

.wysiwyg {
  p {
    @apply mb-8 lg:mb-12 last:mb-0;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply mb-2 xl:mb-4 leading-tight;
  }

  h1 {
    @apply text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 lg:mb-12;
  }

  h2 {
    @apply text-2xl lg:text-3xl xl:text-4xl font-bold;
  }

  h3 {
    @apply text-xl lg:text-2xl xl:text-3xl font-bold;
  }

  ul {
    @apply list-square pl-6;

    li {
      @apply mb-4 lg:mb-6 xl:mb-8;
    }
  }
}

#google-map {
  height: 100%;
  width: 100%;
}


.marquee {
  @apply py-2 lg:py-4 w-full overflow-hidden bg-primary text-white text-center text-xs sm:text-sm lg:text-base;

  > div {
    @apply block relative;
    animation: scroll 28s linear infinite;

    p {
      @apply whitespace-nowrap;
    }

    a {
      @apply font-bold text-white hover:text-white/80;
    }
  }

}

/* Make it move */
@keyframes scroll {
  0% {
    left: 100%;
  }
  100% {
    @apply left-[-200%] lg:left-[-125%];
  }
}