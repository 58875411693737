$pathPrefix: "../fonts/encode-sans-condensed-v5-latin/";

/* encode-sans-condensed-regular - latin */
@font-face {
    font-family: 'Encode Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('#{$pathPrefix}encode-sans-condensed-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-condensed-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-condensed-500 - latin */
@font-face {
    font-family: 'Encode Sans Condensed';
    font-style: normal;
    font-weight: 500;
    src: url('#{$pathPrefix}encode-sans-condensed-v5-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-condensed-v5-latin-500.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-condensed-600 - latin */
@font-face {
    font-family: 'Encode Sans Condensed';
    font-style: normal;
    font-weight: 600;
    src: url('#{$pathPrefix}encode-sans-condensed-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$pathPrefix}encode-sans-condensed-v5-latin-600.woff') format('woff'), /* Modern Browsers */
}

/* encode-sans-condensed-700 - latin */
@font-face {
    font-family: 'Encode Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('#{$pathPrefix}encode-sans-condensed-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$pathPrefix}encode-sans-condensed-v5-latin-700.woff') format('woff'), /* Modern Browsers */
}