.menu-filter {
    @media (screen and min-height: 900px) {
        @apply lg:sticky lg:top-[64px] z-30;
    }
}

.menu-filter, .menu-items {
    .meal-icon {
        svg {
            @apply h-full w-auto;
        }
    }

    input[type="radio"] {
        + label {
            @apply border rounded text-base py-2 px-4 xl:text-xl xl:py-3 xl:px-5 leading-none cursor-pointer hover:bg-gray-100 transition-colors;
        }

        &:checked {
            + label {
                @apply bg-primary text-white border-primary;
            }
        }
    }
}

.block-menu {
    .category-icon > svg {
        @apply max-h-20;
    }
}